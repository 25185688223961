import React, {FC, useEffect} from 'react';
import {IconLoader} from '@components/CustomIcons';
import Icon from '@components/Icon';
import Link from 'next/link';
import {Form, Input} from 'antd';
import {FORM_EMAIL_VALIDATE_RULE, FORM_REQUIRED_RULE} from '@common/constants';
import {useTranslation} from '@common/hooks/useTranslation';
import {IModalFormContentProps, ITranslation} from '@common/types';
import {useRequestForm} from '@common/hooks/useRequest';
import {useTypedSelector} from '@common/hooks/useTypedSelector';
import {settings} from '@common/settings';
import ym from 'react-yandex-metrika';

export const ModalFormContent: FC<IModalFormContentProps> = ({ type }) => {
  const t = useTranslation('forms') as ITranslation['forms']
  const {form, checkFieldLabel, setFocusedInput, sendForm} = useRequestForm(type)
  const {modalForm} = useTypedSelector(state => state.app)
  const {contacts} = useTypedSelector(state => state.contacts)

  useEffect(() => {
    if (modalForm?.status === 'success' && type === 'download') {
      const url = contacts?.file.data?.attributes.url
      if (url) {
        let downloadUrl = url
        fetch(downloadUrl, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' })
          .then(res => res.blob())
          .then(res => {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', contacts?.file.data?.attributes.name ?? 'mu.pdf')
            const href = URL.createObjectURL(res)
            aElement.href = href
            aElement.setAttribute('target', '_blank');
            aElement.click()
            URL.revokeObjectURL(href)
            ym('reachGoal', 'preza')
          })
      }
    }
  }, [modalForm?.status])

  return (
    <Form
      form={form}
      onFocus={(e) => setFocusedInput(e.target.id ?? '')}
      onBlur={() => setFocusedInput('')}
      onFinish={sendForm}
    >
      <div className="form-modal__wrapper">
        {type === 'header' && (
          <Form.Item noStyle={true} name="nameContact" rules={[FORM_REQUIRED_RULE]}>
            <div className="custom-input form-modal__input-box">
              <label>
                <Input id="nameContact"/>
                <span className={`custom-input__label ${checkFieldLabel('nameContact') ? 'is-focus' : ''}`}>
                {t.name}
              </span>
              </label>
            </div>
          </Form.Item>
        )}

        <Form.Item noStyle={true} name="email" rules={[FORM_REQUIRED_RULE, FORM_EMAIL_VALIDATE_RULE]}>
          <div className="custom-input form-modal__input-box">
            <label>
              <Input id="email"/>
              <span className={`custom-input__label ${checkFieldLabel('email') ? 'is-focus' : ''}`}>
                {t.email}
              </span>
            </label>
          </div>
        </Form.Item>

        <button className={`btn btn--violet form-modal__button is-${modalForm?.status}`} type="submit">
          <span className="form-request__button-text">{type === 'request' ? t.button : t.download}</span>
          <IconLoader />
          <Icon
            width={19}
            height={13}
            name="icon-success"
            className="form-modal__button-icon form-modal__button-success"
          />
        </button>
      </div>

      {type === 'header' && (
        <p className="form-request__note">
          {t.policy.text}&nbsp;
          <Link className="form-request__note-link" href="/personal-information">
            {t.policy.textLink}
          </Link>
        </p>
      )}
    </Form>
  )
}
