import React, {useEffect, useState} from 'react';
import Icon from '@components/Icon';
import {useRouter} from 'next/router';
import {translations} from '@common/translations';
import {useActions} from '@common/hooks/useActions';
import {useTranslation} from '@common/hooks/useTranslation';
import {ITranslation} from '@common/types';
import ym from 'react-yandex-metrika';

const ContactButtons = () => {
  const [hide, setHide] = useState<boolean>(true)
  const actions = useActions()
  const t = useTranslation('main') as ITranslation['main']

  useEffect(() => {
    setHide(false)
  }, [])

  return (
    <div className={`contacts-buttons ${hide ? 'is-hide' : ''}`} data-sticky-buttons="">
      <button
        className="contacts-buttons__request"
        onClick={() => actions.setApp({requestForm: {open: true}})}
      >
          <span className="contacts-buttons__text">
            {t.requestTitle}
          </span>
      </button>

      <a
        href="https://t.me/chat_with_mobileup"
        className="contacts-buttons__link"
        target="_blank"
        rel="nofollow noopener noreferrer"
        aria-label={t.telegramLabel}
        onClick={() => ym('reachGoal', 'tg_chat')}
      >
        <Icon width={36} height={36} name="icon-telegram" />
      </a>
    </div>
  )
}

export default ContactButtons
