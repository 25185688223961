import {ILanguage, ITranslation} from '@common/types';

const ru: ITranslation = {
  components: {
    grid: {
      card: {
        linkAriaLabel: 'Переход к проекту'
      }
    },
    award: {
      linkAriaLabel: 'Ссылка на награду'
    },
    detail: {
      reviews: 'Отзывы',
      cooperation: 'Сотрудничество'
    },
    slider: {
      buttonText: 'Как мы это делаем'
    }
  },
  footer: {
    company: 'ООО «Мобайл\xA0Ап» Санкт–Петербург, Москва и весь мир',
    present: 'Скачать презентацию',
    download: 'MobileUp – Цифровые продукты 24.pdf'
  },
  main: {
    requestTitle: 'Отправить заявку',
    telegramLabel: 'Ссылка на телеграм'
  },
  notFoundPage: {
    textGoal: 'Попадание!',
    text404: 'На\xA0страницу 404.',
    textMain: 'Давайте поможем перебраться',
    textLink: 'на\xA0главную'
  },
  forms: {
    blockTitle: 'У вас есть похожая задача? Давайте обсудим!',
    title: 'Давайте знакомиться!',
    name: 'Как вас зовут',
    company: 'Из какой вы компании',
    task: 'Опишите вашу задачу, кратко',
    position: 'За что отвечаете',
    contact: 'Как с Вами связаться',
    phone: 'Телефон',
    email: 'Email',
    download: 'Скачать',
    file: 'Загрузить файл, но не больше 10мб',
    button: 'Отправить заявку',
    policy: {
      text: 'Нажимая «Отправить», вы даете согласие на обработку персональных данных и соглашаетесь c',
      textLink: 'политикой конфиденциальности',
    },
    labels: {
      mb: 'Мб'
    },
    errors: {
      bigFile: '— слишком большой файл',
      message: 'Произошла ошибка при отправке формы. Попробуйте отправить запрос позднее'
    },
    successTooltip: {
      title: 'Ваша заявка успешно отправлена',
      description: 'Мы все изучим и скоро выйдем на связь'
    }
  },
  contactPage: {
    title: 'Наши контакты',
    content: {
      fastTalk: 'Скорее расскажите ',
      aboutProject: 'о\xA0своем проекте.',
      write: ' Пишите на ',
      call: 'звоните',
      or: 'или\xA0',
      onSite: 'на\xA0сайте',
      links: {
        telegraph: 'телеграфируйте',
        request: 'оставьте заявку'
      },
      goToGuests: 'Или приезжайте в гости!',
      footer: {
        title: 'Почта:',
        text: 'Мы\xA0всегда рады новым проектам.',
        link: 'Опишите задачу',
        promise: ', и\xA0мы\xA0с\xA0вами свяжемся.'
      }
    }
  },
  mainContact: {
    title: 'Контакты',
    stepTitle: 'Сотрудничество',
    email: 'Эл. почта',
    cities: [
      {
        name: 'Санкт-Петербург',
        phone: '+7 (812) 648–81–25',
        address: 'ул. Б. Московская 1–3, офис 9'
      },
      {
        name: 'Москва',
        phone: '+7 (495) 008–81–25',
        address: 'ул. Планерная 7, офис 5.6'
      },
    ],
    description: {
      greetings: 'Мы\xA0всегда рады сотрудничеству и\xA0новым проектам.',
      formOpenText: 'Опишите задачу',
      firstPart: ', и\xA0мы\xA0с\xA0вами свяжемся. ',
      secondPart: 'Или напишите в\xA0',
      telegramText: 'Телеграм'
    }
  },
  news: {
    card: {
      case: 'Кейс',
      soon: 'Скоро на сайте'
    },
    telegram: {
      title: 'Телеграм',
      label: 'Мы в телеграме'
    },
    youtube: {
      title: 'Youtube',
      label: 'Мы в ютьюбе'
    },
    vk: {
      title: 'VK',
      label: 'Мы в вк'
    },
  },
  vacancy: {
    hiddenTitle: 'Описание компании MobileUp',
    contact: {
      title: 'Свяжитесь с нами',
      firstText: 'Рассматриваем отклики с\xA0сопроводительным письмом и\xA0ссылкой на\xA0профиль в\xA0соцсетях.',
      secondText: 'Если хочешь стать частью команды, смело пиши на\xA0'
    }
  },
  home: {
    common: {
      titleMobile: 'Все'
    },
    hero: {
      projects: 'проектов',
      years: 'лет\xA0опыта',
      rating: 'в\xA0рейтингах',
      buttonTitle: 'Отправить заявку'
    },
    services: {
      buttonTitle: 'Все услуги'
    },
    news: {
      buttonTitle: 'Все новости'
    },
    project: {
      buttonTitle: 'Все проекты'
    },
    awards: {
      buttonTitle: 'Все достижения'
    },
    review: {
      buttonTitle: 'Все отзывы'
    }
  },
  projects: {
    project: {
      similarProjects: {
        title: 'Похожие\xA0проекты'
      },
      steps: {
        cooperation: {
          title: 'Сотрудничество'
        }
      }
    }
  }
}

const en: ITranslation = {
  components: {
    grid: {
      card: {
        linkAriaLabel: 'Go to project'
      }
    },
    award: {
      linkAriaLabel: 'Link to award'
    },
    detail: {
      reviews: 'Feedback',
      cooperation: 'Collaboration'
    },
    slider: {
      buttonText: 'How we do it'
    }
  },
  footer: {
    company: 'LLC «MobileUp»\n St. Petersburg, Moscow and the whole world',
    present: 'Download presentation',
    download: 'MobileUp – mobile development & web.pdf'
  },
  main: {
    requestTitle: 'Send a request',
    telegramLabel: 'Telegram link'
  },
  notFoundPage: {
    textGoal: 'Great strike!',
    text404: 'You knocked down 404 page',
    textMain: 'Let\'s help you to find\n',
    textLink: 'the\xA0main page'
  },
  forms: {
    blockTitle: 'Do you have a similar task? Let\'s discuss!',
    title: 'Let’s get to know each other!',
    name: 'Your name',
    company: 'Company',
    task: 'Describe your task (briefly)',
    position: 'What are you responsible for',
    contact: 'Phone',
    phone: 'Phone',
    email: 'Email',
    download: 'Download',
    file: 'Upload a file (size must be less than 10 Mb)',
    button: 'Send an application',
    policy: {
      text: 'By clicking \'send\', you consent to our processing your personal data and agree to our',
      textLink: 'privacy policy terms.',
    },
    labels: {
      mb: 'Mb'
    },
    errors: {
      bigFile: '— file too big',
      message: 'An error occurred while submitting the form. Try to send a request later'
    },
    successTooltip: {
      title: 'Your application has been submitted successfully',
      description: 'Thank you! We will contact you soon'
    }
  },
  contactPage: {
    title: 'Contacts',
    content: {
      fastTalk: 'Please tell',
      aboutProject: '\xA0us about your project.',
      write: "Contact\xA0us by\xA0e-mail ",
      telegraph: ', text\xA0us on\xA0',
      onSite: ' on\xA0the site',
      links: {
        telegraph: 'Telegram',
        request: 'leave an\xA0application'
      },
      goToGuests: 'Or сome to our offices!',
      footer: {
        title: 'e-mail:',
        text: 'We\xA0are always glad to\xA0cooperate and work on\xA0new projects',
        link: 'Describe the task',
        promise: ', and we\xA0will contact you soon.'
      }
    }
  },
  mainContact: {
    title: 'contacts',
    stepTitle: 'Collaboration',
    email: 'e-mail',
    cities: [
      {
        name: 'St Petersburg',
        phone: '+7 (812) 648–81–25',
        address: 'Bolshaya Moskovskaya Street 1-3, room 9'
      },
      {
        name: 'Moscow',
        phone: '+7 (495) 008–81–25',
        address: 'Planernaya street 7, room 5.6'
      },
    ],
    description: {
      greetings: 'We\xA0are always glad to\xA0cooperate and work on\xA0new projects',
      formOpenText: 'Describe the task',
      firstPart: ', and we\xA0will contact you soon. Or\xA0you can always text\xA0us on\xA0',
      secondPart: '',
      telegramText: 'Telegram'
    }
  },
  news: {
    card: {
      case: 'Case',
      soon: 'Soon'
    },
    telegram: {
      title: 'Telegram',
      label: 'We are on telegram'
    },
    youtube: {
      title: 'Youtube',
      label: 'We are on youtube'
    },
    vk: {
      title: 'VK',
      label: 'We are in VK'
    },
  },
  vacancy: {
    hiddenTitle: 'Описание компании MobileUp',
    contact: {
      title: 'Свяжитесь с нами',
      firstText: 'Рассматриваем отклики с\xA0сопроводительным письмом и\xA0ссылкой на\xA0профиль в\xA0соцсетях.',
      secondText: 'Если хочешь стать частью команды, смело пиши на\xA0'
    }
  },
  home: {
    common: {
      titleMobile: 'All'
    },
    hero: {
      projects: 'projects',
      years: 'years',
      rating: 'in\xA0the\xA0ratings',
      buttonTitle: 'Send an application'
    },
    services: {
      buttonTitle: 'All services'
    },
    news: {
      buttonTitle: 'All news'
    },
    project: {
      buttonTitle: 'All projects'
    },
    awards: {
      buttonTitle: 'All awards'
    },
    review: {
      buttonTitle: 'All testimonials'
    }
  },
  projects: {
    project: {
      similarProjects: {
        title: 'Similar\xA0projects'
      },
      steps: {
        cooperation: {
          title: 'Collaboration'
        }
      }
    }
  }
}

export const translations: ILanguage = {
  ru,
  en
}
