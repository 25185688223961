import {Form} from 'antd';
import {FC, useEffect, useState} from 'react';
import {NamePath} from 'rc-field-form/es/interface';
import {IRequestFormData} from '@redux/types';
import {useActions} from '@common/hooks/useActions';
import {useTypedSelector} from '@common/hooks/useTypedSelector';
import {TRequestFormType} from '@common/types';
import {REQUEST_FORM_SOURCE} from '@common/constants';
import ym from 'react-yandex-metrika';

export const useRequestForm = (type: TRequestFormType) => {
  const actions = useActions()
  const {modalForm, requestForm, blockForm} = useTypedSelector(state => state.app)
  const [form] = Form.useForm()
  const [focusedInput, setFocusedInput] = useState<string>('')

  useEffect(() => {
    if (requestForm?.open) {
      ym('reachGoal','request_open')
    }
  }, [requestForm?.open])
  
  useEffect(() => {
    const reset = () => {
      form.resetFields()
      setFocusedInput('')
    }

    switch (type) {
      case 'request':
        if (requestForm?.isClear) reset()
        break
      case 'block':
        if (blockForm?.isClear) reset()
        break
      default:
        if (modalForm?.isClear) reset()
        break
    }
  }, [modalForm?.isClear, blockForm?.isClear, requestForm?.isClear])

  const checkFieldLabel = (field: string) => {
    return focusedInput === field || form?.getFieldValue(field as NamePath)
  }
  
  const sendForm = () => {
    let values: IRequestFormData = form.getFieldsValue()
    switch (type) {
      case 'request':
        const source = REQUEST_FORM_SOURCE.find(v => v.value === values?.source)?.name?.ru || null
        values = {
          ...values,
          description: `Заявка из интро\nЗа что отвечаете: ${values?.nameContact}\n
          Опишите вашу задачу, кратко: ${values?.tasks}\nКак с вами связаться: ${values?.phone}\n
          Откуда вы о нас узнали: ${source ?? 'Не указано'}\nИсточник: https://mobileup.ru/`
        }
        break
      case 'header':
        values = {
          ...values,
          description: 'Заявка из хедера',
          phone: '+71111111111',
        }
        break
      case 'download':
        values = {
          ...values,
          description: 'Скачивание презентации',
          phone: '+71111111111',
        }
        break
      case 'block':
        values = {
          ...values,
          description: 'Заявка из кейсов'
        }
        break
    }
    actions.sendRequestForm({
      data: values,
      type
    })
  }
  
  return {
    form,
    focusedInput,
    setFocusedInput,
    checkFieldLabel,
    sendForm
  }
}
